import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconSearchMagnifyingGlass(props: IconProps) {
	return (
		<Icon {...props}>
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="m15 15l6 6m-11-4a7 7 0 1 1 0-14a7 7 0 0 1 0 14"
			/>
		</Icon>
	);
}
